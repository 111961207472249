<template>
  <div class="modal" @click.self="routeRedirection">
    <div class="modal-dialog">
      <span class="close-modal" @click="routeRedirection">X</span>
      <div class="modal-header">
        <div class="modal-header__wrapper">
          <Header color="black" colorBg="white" />
        </div>
      </div>
      <div class="modal-content">
        <h2>Introduce tus datos</h2>
        <div>
        <p>Escribe tu dirección:</p>
          <div class="location__wrapper">
            <GMapAutocomplete
              placeholder="Calle, número y ciudad"
              :select-first-on-enter="true"
              :value="addressDefault"
              ref="inputGmap"
              :options="{
                types: ['geocode'],
                componentRestrictions: {
                  country: ['ES', 'AD']
                }
              }"
              @place_changed="setBasicAddress"
              >
            </GMapAutocomplete>
            <div class="geolocation placeholder" v-if="isLocating">
              <span class="spinner"></span>
            </div>
            <div class="geolocation unavailable" v-else-if="geolocationError">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0C8.852 0 6 2.553 6 5.702C6 8.85 8.602 12.609 12 18C15.398 12.609 18 8.85 18 5.702C18 2.553 15.149 0 12 0ZM12 8C10.895 8 10 7.105 10 6C10 4.895 10.895 4 12 4C13.105 4 14 4.895 14 6C14 7.105 13.105 8 12 8ZM22.881 5.499C22.881 4.007 22.142 2.669 20.979 1.751L21.72 0.999C23.115 2.1 24 3.705 24 5.499C24 7.293 23.115 8.899 21.72 10L20.979 9.247C22.142 8.33 22.881 6.991 22.881 5.499ZM19.5 7.748L20.24 8.499C21.171 7.766 21.761 6.695 21.761 5.499C21.761 4.304 21.171 3.232 20.24 2.499L19.5 3.25C20.197 3.801 20.641 4.604 20.641 5.499C20.641 6.394 20.197 7.198 19.5 7.748ZM3.021 9.247L2.28 10C0.885 8.899 0 7.293 0 5.499C0 3.705 0.885 2.1 2.28 0.999L3.021 1.751C1.858 2.669 1.119 4.007 1.119 5.499C1.119 6.991 1.858 8.33 3.021 9.247ZM3.359 5.499C3.359 4.603 3.802 3.801 4.5 3.25L3.76 2.499C2.829 3.232 2.239 4.304 2.239 5.499C2.239 6.695 2.829 7.766 3.76 8.499L4.5 7.748C3.803 7.198 3.359 6.395 3.359 5.499ZM20 20C20 22.209 16.419 24 12 24C7.581 24 4 22.209 4 20C4 18.398 5.888 17.02 8.608 16.381L9.762 18.205C9.361 18.273 8.956 18.34 8.584 18.447C5.272 19.396 5.131 20.556 8.563 21.549C10.651 22.152 13.34 22.154 15.437 21.548C19.056 20.501 18.601 19.273 15.169 18.381C14.873 18.304 14.548 18.263 14.233 18.21L15.389 16.382C18.112 17.02 20 18.398 20 20Z" fill="black"/>
              </svg>
              <p>Ha ocurrido un error</p>
            </div>
            <div class="geolocation" @click="doGeolocate" v-else>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0C8.852 0 6 2.553 6 5.702C6 8.85 8.602 12.609 12 18C15.398 12.609 18 8.85 18 5.702C18 2.553 15.149 0 12 0ZM12 8C10.895 8 10 7.105 10 6C10 4.895 10.895 4 12 4C13.105 4 14 4.895 14 6C14 7.105 13.105 8 12 8ZM22.881 5.499C22.881 4.007 22.142 2.669 20.979 1.751L21.72 0.999C23.115 2.1 24 3.705 24 5.499C24 7.293 23.115 8.899 21.72 10L20.979 9.247C22.142 8.33 22.881 6.991 22.881 5.499ZM19.5 7.748L20.24 8.499C21.171 7.766 21.761 6.695 21.761 5.499C21.761 4.304 21.171 3.232 20.24 2.499L19.5 3.25C20.197 3.801 20.641 4.604 20.641 5.499C20.641 6.394 20.197 7.198 19.5 7.748ZM3.021 9.247L2.28 10C0.885 8.899 0 7.293 0 5.499C0 3.705 0.885 2.1 2.28 0.999L3.021 1.751C1.858 2.669 1.119 4.007 1.119 5.499C1.119 6.991 1.858 8.33 3.021 9.247ZM3.359 5.499C3.359 4.603 3.802 3.801 4.5 3.25L3.76 2.499C2.829 3.232 2.239 4.304 2.239 5.499C2.239 6.695 2.829 7.766 3.76 8.499L4.5 7.748C3.803 7.198 3.359 6.395 3.359 5.499ZM20 20C20 22.209 16.419 24 12 24C7.581 24 4 22.209 4 20C4 18.398 5.888 17.02 8.608 16.381L9.762 18.205C9.361 18.273 8.956 18.34 8.584 18.447C5.272 19.396 5.131 20.556 8.563 21.549C10.651 22.152 13.34 22.154 15.437 21.548C19.056 20.501 18.601 19.273 15.169 18.381C14.873 18.304 14.548 18.263 14.233 18.21L15.389 16.382C18.112 17.02 20 18.398 20 20Z" fill="black"/>
              </svg>
              <p>Usar mi ubicación</p>
            </div>
          </div>
        </div>
        <div class="location__errors">
          <p v-if="deliveryAddress.visibleAddress && (!deliveryAddress.streetName || !deliveryAddress.postalCode || !deliveryAddress.city)">Debes introducir una dirección válida</p>
          <p v-if="isDelivery && hasPartialAddress && !deliveryAddress.streetNumber">Debes introducir el número de la calle</p>
        </div>
        <div class="goiko__stores" v-if="isTakeaway && hasPartialAddress">
          <div v-if="isSearching" class="search-in-progress">
            <span class="spinner"></span>
          </div>
          <div v-else-if="availableRestaurants.length > 0">
            <h3>¿Dónde quieres recogerlo?</h3>
            <p class="intro">Estos son los GOIKO que te pillan más cerca:</p>
            <div class="stores__wrapper">
              <div class="store__wrapper" v-for="(restaurant, idx) in availableRestaurants" :key="idx" @click="chooseLocation(idx)">
                <label for="chosen_restaurant">
                  <input type="radio" name="chosen_restaurant" :value="idx" v-model="chosenLocation" />
                  <div>
                    <h3>{{ formatRestaurantName(restaurant.title) }}</h3>
                    <p>{{ restaurant.address }}</p>
                    <span>A {{ formatDistance(restaurant.distance) }} de ti</span>
                  </div>
                </label>
              </div>
            </div>
            <OrderTime v-if="chosenLocation !== null" :availableRestaurants="availableRestaurants" :chosenLocation="chosenLocation" v-model:receiveOrderTime="receiveOrderTime" v-model:scheduledTime="scheduledTime" />
            <p class="intro" v-if="availableRestaurants[chosenLocation] ? (!availableRestaurants[chosenLocation].isOpen && receiveOrderTime === 'now') : ''">No podemos entregar el pedido lo antes posible porque el local se encuentra cerrado. Por favor programa el pedido.</p>
            <IsAdult v-if="!user && chosenLocation !== null" v-model:isAdult="isAdult" />
            <a :class="{ disable_item: !canContinue }">
              <button @click="validateAddressAndContinue" class="btn btn-br continue">Continuar y recoger en GOIKO</button>
            </a>
          </div>
          <div v-else>
            <h3>¡Lo sentimos!</h3>
            <p class="intro">
              <template v-if="unavailableZone">Estamos trabajando en tener activo el Takeaway online en tu GOIKO más cercano. Mientras tanto puedes realizar tu pedido directamente en el restaurante.</template>
              <template v-else>Por ahora no tienes un GOIKO cerca.</template>
            </p>
          </div>
        </div>
        <div class="address" v-if="isDelivery && hasFullAddress">
          <div v-if="isSearching" class="search-in-progress">
            <span class="spinner"></span>
          </div>
          <div v-else-if="availableRestaurants.length > 0 && hasGoikoDelivery">
            <h3>Porfa, completa tu dirección:</h3>
            <div class="address__wrapper">
              <label for="delivery-address-block">
                <span>Bloque</span>
                <input type="text" name="delivery-address-block" v-model="deliveryAddress.details.block" />
              </label>
              <label for="delivery-address-other">
                <span>Piso</span>
                <input type="text" name="delivery-address-other" v-model="deliveryAddress.details.floor" />
              </label>
              <label for="address-input">
                <span>Puerta</span>
                <input type="text" name="delivery-address-door" v-model="deliveryAddress.details.door" />
              </label>
              <label for="delivery-address-staircase">
                <span>Escalera</span>
                <input type="text" name="delivery-address-staircase" v-model="deliveryAddress.details.staircase" />
              </label>
            </div>
            <span class="empty-input" v-if="hasEmptyFields">Tienes campos sin rellenar, ¿seguro que quieres continuar?</span>
            <OrderTime :availableRestaurants="availableRestaurants" :chosenLocation="chosenLocation" v-model:receiveOrderTime="receiveOrderTime" v-model:scheduledTime="scheduledTime" />
            <p class="intro" v-if="availableRestaurants[0] ? (!availableRestaurants[0].isOpen && receiveOrderTime === 'now') : ''">No podemos entregar el pedido lo antes posible porque el local se encuentra cerrado. Por favor programa el pedido.</p>
            <IsAdult v-if="!user" v-model:isAdult="isAdult"/>
            <a :class="{ disable_item: !canContinue }">
              <button @click="addressConfirmation" class="btn btn-br continue">Continuar con esta dirección</button>
            </a>
          </div>
          <div v-else-if="availableRestaurants.length > 0" class="no-service">
            <h3>Oops! Goiko Delivery aún no reparte en tu zona.</h3>
            <p>Pero no worries… te lo puede llevar uno de nuestros colegas:</p>
            <div class="no-service__wrapper" v-if="availableRestaurants[0].zones">
              <a @click.stop="redirectToDeliveryCompany(zone.company, zone.url, $event)" v-for="zone in availableRestaurants[0].zones" :key="zone.company" target="_blank"  :class="zone.company">
                <img v-if="zone.company === 'uber'" src="../assets/logo-uber.png" :alt="zone.company">
                <img v-if="zone.company === 'just_eat'" src="../assets/logo-justeat.png" :alt="zone.company">
                <img v-if="zone.company === 'glovo'" src="../assets/logo-glovo.png" :alt="zone.company">
                <img v-if="zone.company === 'cityxerpa'" src="../assets/logo-cityxerpa.png" :alt="zone.company">
              </a>
            </div>
          </div>
          <div v-else class="no-service">
            <h3>Oops! Aún no hay repartos de Goiko en tu zona 😭. <br> <span class="description">Esperamos poder llevarte pronto el chorreo.</span></h3>
            <p>Si te quedas con las ganas 😏, puedes marcar "Recogerlo en GOIKO" y venir a por él.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Header from './Header.vue'
import IsAdult from './StartModal/IsAdult.vue'
import OrderTime from './StartModal/OrderTime.vue'
import { apiClient } from '@/services/ApiClient'
import { JV_STORES } from '../commonMethods/constants'

export default {
  name: 'StartModal',
  components: {
    Header,
    IsAdult,
    OrderTime
  },
  data() {
    return {
      shippingMethod: '',
      deliveryAddress: {
        visibleAddress: '',
        fullAddress: '',
        coordinates: {
          lat: '',
          lng: '',
        },
        streetName: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        details: {
          door: '',
          block: '',
          staircase: '',
          floor: ''
        },
      },
      isAdult: this.isAdult ? this.isAdult : null,
      availableRestaurants: [],
      chosenLocation: null,
      receiveOrderTime: 'now',
      scheduledTime: null,
      hasEmptyFields: false,
      addressDefault: '',
      isSearching: false,
      isLocating: false,
      geolocationError: false,
      closeModal: false,
      unavailableZone: false
    }
  },
  computed: {
    isDelivery() {
      return this.shippingMethod === 'delivery'
    },
    isTakeaway() {
      return this.shippingMethod !== 'delivery'
    },
    hasFullAddress() {
      return Boolean(this.deliveryAddress.visibleAddress && this.deliveryAddress.streetName && this.deliveryAddress.streetNumber && this.deliveryAddress.city && this.deliveryAddress.postalCode)
    },
    hasPartialAddress() {
      return Boolean(this.deliveryAddress.visibleAddress && this.deliveryAddress.streetName && this.deliveryAddress.city && this.deliveryAddress.postalCode)
    },
    hasValidAddress() {
      return Boolean(this.hasFullAddress || (this.isTakeaway && this.hasPartialAddress))
    },
    hasGoikoDelivery() {
      if(this.availableRestaurants.length > 0) {
        return Boolean(this.availableRestaurants[0].hasGoikoZone)
      }
      return false
    },

    canContinue() {
      if (null === this.chosenLocation) {
        return false
      }

      if (!this.availableRestaurants[this.chosenLocation]) {
        return false
      }

      if (null === this.isAdult) {
        return false
      }

      if (!this.availableRestaurants[this.chosenLocation].isOpen && 'now' === this.receiveOrderTime) {
        return false
      }

      return !('now' !== this.receiveOrderTime && null === this.scheduledTime)
    },

    ...mapGetters('Cart', ['getRestaurantInfo', 'getDeliveryAddress']),
    ...mapState('User', ['user']),
    ...mapState('Cart', ['orderInfo'])
  },
  methods: {
    ...mapActions('Cart', ['saveConfig', 'getNewShippingMethod', 'getNewAddress', 'checkProductsCart']),
    ...mapActions('User', ['fetchProfile']),
    getAvailableRestaurants([shippingMethod, lat, lng ]) {
      return new Promise((resolve, reject) => {
        apiClient.post(`/restaurant/search-${shippingMethod}`, {
          "latitude": lat,
          "longitude": lng
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err.response.data.errors)
        })
      })
    },

    updateMethod(newMethod) {
      if (!this.user && this.$router.currentRoute._rawValue.path.includes('iniciar-pedido')) {
        this.isAdult = null
      }
      this.shippingMethod = newMethod
      this.getNewShippingMethod(newMethod)
      this.getOptionsByAddress()
    },

    chooseLocation(idx) {
      this.chosenLocation = idx
      this.scrollModalToEnd()
    },
    resetAddress() {
      this.deliveryAddress.visibleAddress = ''
      this.deliveryAddress.fullAddress = ''
      this.deliveryAddress.coordinates.lat = ''
      this.deliveryAddress.coordinates.lng = ''
      this.deliveryAddress.city = ''
      this.deliveryAddress.postalCode = ''
      this.deliveryAddress.streetNumber = ''
      this.deliveryAddress.streetName = ''
    },
    setBasicAddress(autocompleteResults) {
      if (this.$router.currentRoute._rawValue.path === '/iniciar-pedido') {
        this.resetAddress()
      }
      if(autocompleteResults.formatted_address) {
        this.deliveryAddress.visibleAddress = (autocompleteResults.name) ? autocompleteResults.name : autocompleteResults.formatted_address
        this.deliveryAddress.fullAddress = autocompleteResults.formatted_address
        this.deliveryAddress.coordinates.lat = autocompleteResults.geometry.location.lat()
        this.deliveryAddress.coordinates.lng = autocompleteResults.geometry.location.lng()

        autocompleteResults.address_components.forEach(component => {
          if (JV_STORES[component.short_name]) {
            this.unavailableZone = true
          }
          switch(component.types[0]) {
            case "locality":
              this.deliveryAddress.city = component.short_name
              break;
            case "postal_code":
              this.deliveryAddress.postalCode = component.short_name
              break;
            case "street_number":
              this.deliveryAddress.streetNumber = component.short_name
              break;
            case "route":
              this.deliveryAddress.streetName = component.short_name
              break;
          }
        })
        this.getOptionsByAddress()
      }
    },
    getOptionsByAddress() {
      if(!this.hasValidAddress) return

      this.isSearching = true
      this.availableRestaurants = []
      this.searchRestaurants()
    },
    validateAddressAndContinue() {
      this.saveConfig(['restaurant', this.availableRestaurants[this.chosenLocation]])
      this.saveConfig(['shippingMethod', this.shippingMethod])
      this.saveConfig(['deliveryAddress', this.deliveryAddress])
      this.saveConfig(['orderTime', this.scheduledTime])
      this.saveConfig(['isAdult', this.isAdult])
      this.saveConfig(['orderInfo', []])
      this.saveConfig(['coupons', []])
      this.saveConfig(['cartId', null])
      if (this.shippingMethod === 'delivery') {
        this.$gtag.event('select_delivery_method', {  method: 'delivery_bygoiko'  })
      } else {
        this.$gtag.event('select_delivery_method', {  method: 'takeaway'  })
      }
      this.$gtag.event('choose_location', { location: this.availableRestaurants[this.chosenLocation].code })
      if (this.$router.currentRoute._rawValue.fullPath === '/finalizar-compra/datos-entrega') {
        this.getNewAddress(this.deliveryAddress)
        this.$router.push({ name: 'checkout'})
        this.checkProductsCart()
      } else {
        this.$router.push({name: 'catalog'})
      }
    },
    doGeolocate() {
      this.isLocating = true
      navigator.geolocation.getCurrentPosition((position) => {
        var geocoder = new google.maps.Geocoder()
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        geocoder.geocode(
          {'location': latlng},
          (results, status) => {
            if ('OK' !== status) {
              console.log('Geocode was not successful for the following reason: ' + status)
              return
            }
            this.setBasicAddress(results[0])
            this.addressDefault = results[0].formatted_address
            this.isLocating = false
          }
        )
      }, (error) => {
        this.isLocating = false
        this.geolocationError = error
      })
    },
    formatDistance(dist) {
      if(dist >= 1000) {
        return (Math.round(dist / 100) / 10) + ' km'
      } else {
        return Math.round(dist) + ' m'
      }
    },
    formatRestaurantName(name) {
      let namePieces = name.split(',')
      return 'GOIKO ' + namePieces[0]
    },

    addressConfirmation() {
      if (this.hasEmptyFields == true) {
        this.validateAddressAndContinue()
        return
      }

      if (this.deliveryAddress.details.door == '' && this.deliveryAddress.details.block == '' && this.deliveryAddress.details.staircase == '') {
        this.hasEmptyFields = true
      } else {
        this.validateAddressAndContinue()
      }
      this.user ? this.isAdult = this.user.isAdult : this.isAdult
    },
    scrollModalToEnd() {
      if (window.history.state.current === '/iniciar-pedido') {
        window.setTimeout(() => {
          document.querySelector('.modal-content').scrollTo({
            top: 1000,
            behavior: 'smooth',
          })
        }, 100)
      }
    this.$gtag.event('select_delivery_method', {  method: 'delivery_bygoiko' })
    this.$gtag.event('choose_location', { location: this.availableRestaurants[this.chosenLocation].code })
    },
    searchRestaurants() {
      this.getAvailableRestaurants([this.shippingMethod, this.deliveryAddress.coordinates.lat, this.deliveryAddress.coordinates.lng])
      .then((data) => {
        if(this.shippingMethod == 'delivery' && data) {
          this.chosenLocation = 0
          this.availableRestaurants.push(data)
        }
        if(this.shippingMethod == 'takeaway' && data) {
          // this.chosenLocation = null
          if (this.$router.currentRoute._rawValue.path === '/iniciar-pedido') {
            this.chosenLocation = null
          } else {
            this.chosenLocation = this.chosenLocation
          }
          this.availableRestaurants = data
        }
        this.isSearching = false
        this.scrollModalToEnd()
      })
      .catch(() => {
        this.isSearching = false
      })
    },
    trackExternalDeliveryMethod(deliveryCompany) {
      let companyName = ''
      switch (deliveryCompany) {
        case 'just_eat':
          companyName = 'delivery_justeat'
          break;
        case 'glovo':
          companyName = 'delivery_glovo'
          break;
        default:
          companyName = 'delivery_uber'
          break;
      }
      this.$gtag.event('select_delivery_method', {  method: companyName  })
    },
    redirectToDeliveryCompany(deliveryCompany, url) {
      this.trackExternalDeliveryMethod(deliveryCompany)
      window.location.href = url
    },
    routeRedirection() {
      if (this.$router.options.history.state.back) {
        this.$router.go(-1)
      } else if (this.$router.currentRoute._rawValue.path.includes('delivery/iniciar-pedido')) {
        this.$router.push({ name: 'home' })
      } else if (this.$router.currentRoute._rawValue.path.includes('iniciar-pedido')) {
        this.$router.push({ name: 'takeaway-home' })
      } else if (this.$router.currentRoute._rawValue.path === '/finalizar-compra/datos-entrega') {
        this.$router.push({ name: 'checkout' })
      } else {
        this.$router.push({ name: 'catalog' })
      }
    }
  },
  mounted() {
    this.shippingMethod = (this.$router.currentRoute._rawValue.path.includes('takeaway')) ? 'takeaway' : 'delivery'

    this.fetchProfile()
      .then(data => {
        if (data) { this.isAdult = data.isAdult }
      })

    if (this.$route.query.type && this.$route.query.street && this.$route.query.postalCode
        && this.$route.query.city && this.$route.query.latitude && this.$route.query.longitude
        && (this.$route.query.type == 'delivery' || this.$route.query.type == 'takeaway')) {
      this.isSearching = true
      this.shippingMethod = this.$route.query.type
      this.deliveryAddress.visibleAddress = `${this.$route.query.street}${this.$route.query.number == undefined ? '' : `, ${this.$route.query.number}`}, ${this.$route.query.postalCode} ${this.$route.query.city}`
      this.deliveryAddress.fullAddress = `${this.$route.query.street}, ${this.$route.query.number}, ${this.$route.query.postalCode} ${this.$route.query.city}`
      this.deliveryAddress.coordinates.lat = this.$route.query.latitude
      this.deliveryAddress.coordinates.lng = this.$route.query.longitude
      this.deliveryAddress.city = this.$route.query.city
      this.deliveryAddress.postalCode = this.$route.query.postalCode
      this.deliveryAddress.streetNumber = this.$route.query.number
      this.deliveryAddress.streetName = this.$route.query.street
      this.deliveryAddress.details.block = (this.$route.query.block == undefined) ? '' : this.$route.query.block
      this.deliveryAddress.details.floor = (this.$route.query.floor == undefined) ? '' : this.$route.query.floor
      this.deliveryAddress.details.door = (this.$route.query.door == undefined) ? '' : this.$route.query.door
      this.deliveryAddress.details.staircase = (this.$route.query.staircase == undefined) ? '' : this.$route.query.staircase
      this.addressDefault = this.deliveryAddress.visibleAddress
      this.searchRestaurants()
    }
    if (this.$router.currentRoute._rawValue.path === '/finalizar-compra/datos-entrega' || this.$router.currentRoute._rawValue.path === '/catalogo/datos-entrega') {
      this.shippingMethod = JSON.parse(localStorage.getItem('shippingMethod'))
      this.isAdult = JSON.parse(localStorage.getItem('isAdult'))
      this.availableRestaurants = JSON.parse(localStorage.getItem('restaurant'))
      let deliveryAddressInfo = JSON.parse(localStorage.getItem('deliveryAddress'))
      this.deliveryAddress.fullAddress = deliveryAddressInfo.fullAddress
      this.deliveryAddress.coordinates.lat = deliveryAddressInfo.coordinates.lat
      this.deliveryAddress.coordinates.lng = deliveryAddressInfo.coordinates.lng
      this.deliveryAddress.visibleAddress = deliveryAddressInfo.visibleAddress
      this.deliveryAddress.streetName = deliveryAddressInfo.streetName
      this.deliveryAddress.streetNumber = deliveryAddressInfo.streetNumber
      this.deliveryAddress.details.block = deliveryAddressInfo.details.block
      this.deliveryAddress.details.floor = deliveryAddressInfo.details.floor
      this.deliveryAddress.details.door = deliveryAddressInfo.details.door
      this.deliveryAddress.details.staircase = deliveryAddressInfo.details.staircase
      this.addressDefault = deliveryAddressInfo.fullAddress
      this.updateMethod(this.shippingMethod)
      this.searchRestaurants()
    }
  }
}
</script>

<style scoped lang="scss">

.modal {
  h2 {
    padding: 54px 30px 30px 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font__headings;
    text-transform: uppercase;
    text-align: unset;

    @include tablet {
      padding: 0 30px 30px 0
    } 
  }

  h3 {
    font-size: $fs-xs-medium;
    font-weight: $fw-bold;
    position: relative;
    margin: 0;

    span {
      font-weight: normal;
    }
  }

  .modal-dialog {
    min-width: 60vw;
    .modal-header {
      height: 60px;
    }
    .modal-content {
      background-color: $c-white;
      padding: 30px;
      padding-bottom: 80px;
      height: calc(100vh - 110px);
      overflow-y: auto;
      overflow-x: hidden;

      .checkbox__wrapper {
        display: flex;
        flex-direction: row;
        margin: 20px;
        width: 100%;
        gap: 40px;
        
        label {
          white-space: nowrap;
          &:first-child {
            margin-bottom: 20px;
          }
        }

        .hours {
          margin-left: 10px;
        }

      }
      p {
        font-size: $fs-s-small;
        font-weight: $fw-regular;
        margin: 0;
      }

      .intro {
        margin-bottom: 24px;
      }

      .location__wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;

        input {
          height: 44px;
          border: 1px solid $c-border-input;
          border-radius: 6px;
          margin-top: 6px;
          margin-bottom: 10px;
          padding: 0 10px;
          width: 100%;
        }

        .geolocation {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;
          }

          p {
            text-decoration: underline;
            font-weight: $fw-bold;
          }

          &.placeholder {
            position: relative;
            height: 50px;
            width: 150px;
          }

          &.unavailable {
            opacity: .4;
            cursor: not-allowed;
            p {
              text-decoration: none;
            }
          }
        }
      }
      .location__errors {
        p {
          font-size: 12px;
          color: $c-red;
        }
      }
    }
  }

  @include tablet {
    padding: 1rem;

    .modal-dialog {
      .modal-header {
        display: none;
      }

      .modal-content {
        height: 500px;
        padding-bottom: 30px;

        .location__wrapper {
          flex-direction: row;
          flex-wrap: nowrap;

          input {
            width: 65%;
            margin-right: 20px;
          }
        }
      }
    }
  }

}

.goiko__stores {
  padding-top: 20px;
  h3 {
    margin: 1em 0;
  }
  
  .stores__wrapper {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    padding: 0 30px;
  
    .store__wrapper {
      border: 1px solid $c-black;
      border-radius: 6px;
      padding: 20px;
      padding-bottom: 40px;
      position: relative;
      width: 100%;
      margin: 0 auto 20px;
      cursor: pointer;
  
      input {
        position: absolute;
        left: -6px;
        top: -6px;
  
        & + div {
          position: unset;
          height: 100%;
          h3 {
            font-size: 16px;
            margin: 0 0 .5em 0;
          }
          span {
            position: absolute;
            bottom: 10px;
            right: 15px;
            color: $c-gray;
          }
          &::before {
            left: -7px;
            top: -2px;
            right: unset;
            background-color: $c-white;
          }
          &::after {
            left: -3px;
            top: -4px;
          }
        }

        &:checked + div::before {
          background-color: $c-black;
        }
      }
    }
  }
  
  a {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  @include tablet {
    a {
      justify-content: flex-end;
    }

    .stores__wrapper {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      .store__wrapper {
        width: 240px;
        max-width: 100%;
        margin: 20px 0;
      }
    }
  }

}

.address {
  padding-top: 20px;

  h3 {
    margin: 1em 0;
  }

  .address__wrapper {
    display: flex;
    flex-wrap: wrap;
  
    label {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      max-width: 31%;
  
      span {
        font-family: $font__main;
        font-size: $fs-s-small;
      }
  
      input {
        max-width: 80px;
      }
  
      &:last-child {
        input {
          max-width: 200px;
        }
      }
    }
  }

  a {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .continue {
      margin-bottom: 80px;
    }
  }

  .empty-input {
      font-size: $fs-m-small;
      color: $c-red;
      margin: 0px;
  }

  @include tablet {
    .address__wrapper {
      label {
        max-width: unset;
      }
    }

    a {
      justify-content: flex-end;

      .continue {
        margin-bottom: 0;
    }
    }
  }

}

.no-service {
    h3 {
      margin: 2px 0;
      margin-left: -2px;
      margin-bottom: 4px;
    }

    .description {
      font-size: $fs-s-small;
      font-weight: normal;
    }

    @include tablet {
      br {
        display: none;
      }
    }

  .no-service__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: space-evenly;
    margin: 10px;
    .windelivery { display: none !important; }
    a {
      cursor: pointer;
      margin: 0;
    }
    img {
      width: 180px;
      height: 100px;
      object-fit: contain;
      object-position: center;
      padding: 20px;
      border: 1px solid $c-border-input;
      border-radius: 6px;
      margin: 20px;
    }
  }
}

.search-in-progress {
  width: 100%;
  height: 0px;
  max-width: 450px;
  position: relative;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

</style>
