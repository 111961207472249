<template>
  <template v-if="shippingMethod === 'takeaway'">
    <h3 :class="{ 'desktop': $route.name === 'catalog-rest' }">¿Cuándo quieres recogerlo?</h3>
  </template>
  <template v-else-if="shippingMethod === 'delivery'">
    <h3>¿Cuándo quieres recibirlo?</h3>
  </template>
  <template v-if="availableRestaurants.length">
    <div class="checkbox__wrapper">
      <label :class="{disabled: !availableRestaurants[chosenLocation].isOpen}" v-bind="$attrs">
        <input type="radio" name="pickup_time" value="now" v-model="receiveOrderTime" @change="$emit('update:receiveOrderTime', $event.target.value)"/>
          <span>Lo antes posible</span>
          <span v-if="availableRestaurants[chosenLocation].isOpen" class="time">Aproximadamente a las {{getEstimateTime}}</span>
      </label>
      <div class="scheduled">
        <label>
          <input type="radio" name="pickup_time" value="scheduled" v-model="receiveOrderTime" @change="$emit('update:receiveOrderTime', $event.target.value)"/>
          <span>Programar mi pedido</span>
        </label>
        <span class="hours" v-if="receiveOrderTime === 'scheduled'">
          <Select2 v-model="scheduledTime" :options="getRestaurantTime" :settings="{ minimumResultsForSearch: Infinity }" @select="$emit('update:scheduledTime', $event.id)"/>
        </span>
      </div>
    </div>
  </template>
</template>

<script>
import Select2 from 'vue3-select2-component'
import { mapState } from 'vuex'
import { formatMonth } from '../../commonMethods/methods'

export default {
  name: 'OrderTime',
  emits: ['update:receiveOrderTime', 'update:scheduledTime'],
  props: {
    availableRestaurants: Array,
    chosenLocation: Number,
  },
  components: {
    Select2
  },
  data() {
    return {
      receiveOrderTime: 'now',
      scheduledTime: null,
    }
  },
  computed: {
    ...mapState('Cart', ['shippingMethod', 'orderTime']),
    getRestaurantTime() {
      let timeArr = []
      let dayStart = null
      this.availableRestaurants.forEach((restaurant, idx) => {
        if (this.chosenLocation == idx) {
          restaurant.weekDates.forEach((weekDate) => {
            let slot = new Date(weekDate.dateStart * 1000)
            const dateEnd = new Date(weekDate.dateEnd * 1000)
  
            while (slot.getTime() <= dateEnd.getTime()) {
              if (slot.getDate() !== dayStart) {
                let month = `${formatMonth(slot.getMonth())}`
                timeArr.push({ id: 0, text: `${slot.getDate()} de ${month}`, disabled: true })
              }
  
              dayStart = slot.getDate()
              
              timeArr.push({
                id: slot.getTime(),
                text: `${slot.getHours()}:${slot.getMinutes() <= 9 ? '0' + slot.getMinutes() : slot.getMinutes()}`
              })
              slot.setMinutes(slot.getMinutes() + 15)
            }
          })
        }
      })
      return timeArr
    },
    getEstimateTime() {
      let estimateTime;
      if (this.availableRestaurants.length === 1) {
        estimateTime = this.convertDate(this.availableRestaurants[0])
      } else {
        this.availableRestaurants.forEach((restaurant, idx) => {
          if (this.chosenLocation === idx) {
            estimateTime = this.convertDate(restaurant)
          }
        })
      }
      return estimateTime
    },
  },
  methods: {
    convertDate(restaurant) {
      let date = new Date(restaurant.estimatedTime * 1000)
      let hours = date.getHours()
      let minutes = date.getMinutes()
      return `${hours}:${minutes <= 9 ? `0${minutes}` : minutes}`
    }
  },
  mounted() {
    if (this.orderTime) {
      this.receiveOrderTime = 'scheduled'
      this.scheduledTime = this.orderTime
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox__wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;
  
  label {
    &:first-child {
      margin-right: 40px;
      margin-bottom: 20px;
    }
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
    .scheduled {
      display: flex;
      align-items: center;

    .hours {
      margin-bottom: 15px;
      margin-left: -20px;
    }
  }

  .time {
    font-size: $fs-s-small;
    font-weight: $fw-bold;
    margin-left: 10px;
  }

  @include tablet {
   .scheduled {
      align-items: flex-start;
      .hours {
        margin-top: -8px;
     }
    }
  }
}
@include tablet {   
  .desktop {
    margin: 0;
  }
}
</style>
