<template>
  <h3 :class="{ 'desktop': $route.name === 'catalog-rest' }">¿Eres mayor de 18 años?</h3>
  <div class="checkbox__wrapper">
    <label>
      <input v-bind="$attrs" :value="true" type="radio" v-model="isAdult" @change="$emit('update:isAdult', true)" />
      <span>Sí</span>
    </label>
    <label>
      <input v-bind="$attrs" :value="false" type="radio" v-model="isAdult" @change="$emit('update:isAdult', false)" />
      <span>No</span>
    </label>
  </div>
</template>

<script>
export default {
  emits: ['update:isAdult'],
  data() {
    return {
      isAdult: null
    }
  },
  mounted() {
    this.isAdult = this.$router.currentRoute._rawValue.path.includes('iniciar-pedido') ? null : JSON.parse(localStorage.getItem('isAdult'))
  }
}
</script>

<style lang="scss" scoped>
.checkbox__wrapper {
  display: flex;
  flex-direction: row;
  margin: 20px;
  width: 100%;
  gap: 40px;
  label {
    white-space: nowrap;
    &:first-child {
      margin-bottom: 20px;
    }
  }
  .hours {
    margin-left: 10px;
  }
}
@include tablet {   
  .desktop {
    margin: 0;
  }
}
</style>
