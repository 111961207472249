import { apiClient } from '@/services/ApiClient'
import { event } from 'vue-gtag'

const initialState = () => ({
  deliveryAddress: localStorage.getItem('deliveryAddress') ? JSON.parse(localStorage.getItem('deliveryAddress')) : {
    visibleAddress: '',
    fullAddress: '',
    coordinates: {
      lat: '',
      lng: '',
    },
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    details: {
      door: '',
      block: '',
      staircase: '',
      floor: '',
    },
  },
  isAdult: JSON.parse(localStorage.getItem('isAdult')),
  orderInfo: localStorage.getItem('orderInfo') ? JSON.parse(localStorage.getItem('orderInfo')) : [],
  restaurant: JSON.parse(localStorage.getItem('restaurant')),
  cartId: JSON.parse(localStorage.getItem('cartId')),
  upsellingProducts: [],
  config: {
    deliveryCost: 0,
    hasDeliveryCost: true,
    minimumOrderFreeDelivery: 0,
    serviceCost: 0,
  },
  shippingMethod: localStorage.getItem('shippingMethod') ? JSON.parse(localStorage.getItem('shippingMethod')) : 'delivery',
  orderTime: JSON.parse(localStorage.getItem('orderTime')),
  productsNotAvailable: [],
  orderToken: null,
  coupons: localStorage.getItem('coupons') ? JSON.parse(localStorage.getItem('coupons')) : [],
  errorsCoupons: [],
  genericErrors: [],
  successCoupons: [],
  validCoupons: [],
  existingCouponError: '',
  goikoId: null,
  productPlu: '',
  productsToChoose: [],
  wasChoosed: true,
  successCouponMsg: '',
  productInCartIndex: null
})

const emptyState = () => ({
  deliveryAddress: {
    visibleAddress: '',
    fullAddress: '',
    coordinates: {
      lat: '',
      lng: '',
    },
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    details: {
      door: '',
      block: '',
      staircase: '',
      floor: '',
    },
  },
  isAdult: null,
  orderInfo: [],
  restaurant: null,
  cartId: null,
  upsellingProducts: [],
  config: {
    deliveryCost: null,
    hasDeliveryCost: null,
    minimumOrderFreeDelivery: null,
    serviceCost: 0,
  },
  shippingMethod: 'delivery',
  orderTime: null,
  orderToken: null,
  coupons: [],
  goikoId: null
})

const state = initialState()

const actions = {
  getAvailableRestaurants([shippingMethod, lat, lng ]) {
    return new Promise((resolve, reject) => {
      apiClient.post(`/restaurant/search-${shippingMethod}`, {
        "latitude": lat,
        "longitude": lng
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },

  addProductToCart({commit}, product) {
    commit('ADD_TO_CART', product)
  },

  checkProductsCart({ dispatch, commit, getters, state }, params) {
    let checkCart = getters.prepareCartInfo
    let getIfAdult = getters.getIfAdult
    let getIfCouponsValidate = getters.getIfCouponsValidate
    let doRefresh = false

    if (typeof params === 'object' && params) {
      dispatch('handleCoupons', { checkCart, coupon: params })
    }

    if (typeof params === 'boolean' && params) {
      doRefresh = true
    }

    const restaurantPromiseFunc = (response) => {
      commit('SAVE_CART_INFO', response.data)
      commit('SAVE_INFO', ['orderInfo', state.orderInfo])
      commit('SAVE_INFO', ['restaurant', response.data.restaurant])
      commit('SAVE_INFO', ['coupons', checkCart.coupons])
      commit('SAVE_INFO', ['cartId', response.data.cartId])
    }
    
    let isAdult = getIfAdult ? '?adult' : ''
    let refreshCoupon = getIfCouponsValidate && doRefresh ? `${getIfAdult ? '&' : '?'}refreshCoupon` : ''

    return new Promise((resolve) => {
      let restaurant = getters.getRestaurantInfo
      apiClient.post(`/cart/${restaurant.id}/check${isAdult}${refreshCoupon}`, checkCart)
        .then(response => {
          restaurantPromiseFunc(response)
          resolve(response.data)
        })
        .catch((err) => {
          restaurantPromiseFunc(err.response)
          resolve(err.response.data)
      })
    })
  },

  handleCoupons({ commit }, { checkCart, coupon }) {
    const couponExists = checkCart.coupons.some((item) => item.code === coupon.code)

    if (!couponExists) {
      checkCart.coupons.push({
        code: coupon.code.toUpperCase(),
        triggerChosenProducts: coupon.triggerChosenProducts,
        promotionChosenProducts: coupon.promotionChosenProducts
      })
    } else {
      if (coupon.isValidating) {
        const couponIndex = checkCart.coupons.findIndex((item) => item.code === coupon.code)

        if (couponIndex !== -1) {
          checkCart.coupons[couponIndex] = {
            ...checkCart.coupons[couponIndex],
            triggerChosenProducts: coupon.triggerChosenProducts,
            promotionChosenProducts: coupon.promotionChosenProducts
          }
        }
      } else {
        commit('EXISTING_COUPON', `El código ${coupon.code} ya ha sido aplicado`)
      }
    }
  },

  handleErrorsCoupons({ commit }, errors) {
    commit('SET_COUPONS_ERRORS', errors)
  },

  handleGenericErrors({ commit }, error) {
    commit('SET_GENERIC_ERRORS', error)
  },

  saveConfig({ commit }, [type, value]) {
    commit('SAVE_INFO', [type, value])
  },

  addQuantityToProduct({commit}, quantity) {
    commit('ADD_QUANTITY', quantity)
  },

  removeQuantityToProduct({commit}, quantity) {
    commit('REMOVE_QUANTITY', quantity)
  },

  getProductPluToDiscount({commit}, plu) {
    commit('PRODUCT_PLU', plu)
  },

  processCart({commit, getters}, checkoutData) {
    let getIfAdult = getters.getIfAdult
    let checkCart = {
      ...getters.prepareCartInfo,
      ...checkoutData,
    }

    let isAdult = getIfAdult ? '?adult' : ''
    
    return new Promise((resolve, reject) => {
      let restaurant = getters.getRestaurantInfo
      apiClient.post(`/cart/${restaurant.id}/process${isAdult}`, checkCart)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          commit('SAVE_INFO', ['orderInfo', state.orderInfo])
          reject(err.response.data)
        })
    })
  },

  getOrderByToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/orders?token=${token}`)
        .then(response => {
          commit('SAVE_ORDER_TOKEN', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTimeToChange({ commit }, time) {
    commit('TIME_RECEIVED', time)
  },
  getNewShippingMethod({commit}, method) {
    commit('METHOD_RECEIVED', method)
  },
  getNewAddress({commit}, address) {
    commit('ADDRESS_RECEIVED', address)
  },
  wasChoosedProduct({commit}, boolean) {
    commit('WAS_PRODUCT_CHOOSED', boolean)
  },
  removeProductFromCart({ commit}, index) {
    commit('REMOVE_PRODUCT_CART', index)
  },
  addValidCoupon({ commit }, validCoupons) {
    commit('ADD_VALID_COUPONS', validCoupons)
  },
  removeCoupon({ commit }, code) {
    commit('REMOVE_COUPON', code)
  }
}

const mutations = {
  SAVE_ORDER_TOKEN(state, token) {
    state.orderToken = token
  },

  SAVE_CART_INFO(state, data) {
    state.validCoupons = data.validCoupons
    state.restaurant = data.restaurant
    state.config = data.config
    state.upsellingProducts = data.upsellingProducts
    state.productsNotAvailable = data.productsNotAvailable
    state.goikoId = data.goikoId
    state.productsToChoose = data.productsToChoose
    state.orderInfo.forEach(product => {
      product.isProductAvailable = !data.productsNotAvailable.includes(product.plu)

      product.hasUnavailableModifiers = []
      product.modifiers.forEach(modifier => {
        if (data.modifiersNotAvailable.includes(modifier.plu)) {
          product.hasUnavailableModifiers.push(modifier.name)
        }
      })

      product.hasUnavailableProducts = []
      product.hasComboProducts.forEach(comboProduct => {
        if (data.productsNotAvailable.includes(comboProduct.plu)) {  
          product.hasUnavailableProducts.push(comboProduct.name)
          product.isProductAvailable = false
        }
      })
    })
  },
  
  ADD_TO_CART(state, product) {
    const index = state.orderInfo.findIndex(item => {
      if (item.plu === product.plu && item.modifiers.length === product.modifiers.length) {
        const getPlus = (list) => list.map(item => item.plu).sort()
        return JSON.stringify(getPlus(product.modifiers)) === JSON.stringify(getPlus(item.modifiers))
      }
    })

    if (index !== -1) {
      state.orderInfo[index].quantity += 1
    } else {
      if (product.productToModifyIndex !== null && product.modifiers.length) {
        state.orderInfo[product.productToModifyIndex].modifiers = product.modifiers
      } else {
        state.orderInfo.push(product)
      }
    }

    this.commit('Cart/SAVE_INFO', ['orderInfo', state.orderInfo])
  },  
  REMOVE_PRODUCT_CART(state, idx) {
    state.orderInfo.splice(idx, 1)
    this.commit('Cart/SAVE_INFO', ['orderInfo', state.orderInfo])
  },

  ADD_QUANTITY(state, idx) {
    if (state.orderInfo[idx].isProductAvailable === true) {
      state.orderInfo[idx].quantity++
      this.commit('Cart/SAVE_INFO', ['orderInfo', state.orderInfo])
      this.dispatch('Cart/checkProductsCart', true)
    }
    
    event('add_to_cart', {
      currency: 'EUR',
      value: state.orderInfo[idx].price / 100,
      items: [{
        item_id: state.orderInfo[idx].plu,
        item_name: state.orderInfo[idx].name
      }]
    })
  },

  REMOVE_QUANTITY(state, idx) {
    event('remove_from_cart', {
      currency: 'EUR',
      value: state.orderInfo[idx].price / 100,
      items: [{
        item_id: state.orderInfo[idx].plu,
        item_name: state.orderInfo[idx].name
      }]
    })
    if (state.orderInfo[idx].quantity == 1) {
      state.orderInfo.splice(idx, 1)
    } else if (state.orderInfo[idx].isProductAvailable === false) {
      state.orderInfo.splice(idx, state.orderInfo[idx].quantity - state.orderInfo[idx].quantity)
    } else {
      state.orderInfo[idx].quantity--
    }
    this.commit('Cart/SAVE_INFO', ['orderInfo', state.orderInfo])
    this.dispatch('Cart/checkProductsCart', true)
  },

  TOTAL_PRICE(state, idx) {
    let total = 0
    state.orderInfo[idx].modifiers.forEach(modifier => total += modifier.price)
    return total
  },
  ADD_COUPON(state) {
    state.successCouponMsg = 'Código válido'
    setTimeout(() => state.successCouponMsg = '', 4000)
  },
  WAS_PRODUCT_CHOOSED(state, boolean) {
    state.wasChoosed = boolean
  },
  TIME_RECEIVED(state, time) {
    state.orderTime = time
    if (!time) {
      state.orderTime = state.restaurant.estimatedTime
    }
  },
  METHOD_RECEIVED(state, method) {
    state.shippingMethod = method
  },
  ADDRESS_RECEIVED(state, address) {
    state.deliveryAddress = address
  },
  SAVE_INFO(state, [type, value]) {
    if (state.hasOwnProperty(type)) {
      state[type] = value
    }

    localStorage.setItem(type, JSON.stringify(value))
  },
  RESET_ORDER_INFO_STORAGE(state) {
    localStorage.removeItem('restaurant')
    localStorage.removeItem('orderInfo')
    localStorage.removeItem('deliveryAddress')
    localStorage.removeItem('shippingMethod')
    localStorage.removeItem('orderTime')
    localStorage.removeItem('coupons')
    localStorage.removeItem('isAdult')
    Object.assign(state, emptyState())
  },
  RESET_QUAL_LEADS_INFO_STORAGE() {
    localStorage.removeItem('restaurant')
    localStorage.removeItem('deliveryAddress')
    localStorage.removeItem('shippingMethod')
    localStorage.removeItem('orderTime')
    localStorage.removeItem('isAdult')
  },
  PRODUCT_PLU(state, plu) {
    state.productPlu = plu
    this.commit('Cart/SAVE_INFO', ['couponProductChoosed', state.productPlu])
  },
  ADD_VALID_COUPONS(state, validCoupons) {
    state.validCoupons = validCoupons.map(({ discount, code }) => ({ code, discount }))
  },
  REMOVE_COUPON(state, code) {
    state.coupons = state.coupons.filter((coupon) => coupon.code !== code)
    localStorage.setItem('coupons', JSON.stringify(state.coupons))
    this.dispatch('Cart/checkProductsCart')
      .then(({ validCoupons, errorCoupons }) => {
        if (!Array.isArray(errorCoupons)) {
          this.dispatch('Cart/handleErrorsCoupons', errorCoupons)
        } else if (validCoupons.length > 0) {
          state.coupons = state.coupons.map((coupon, index) => ({
              ...coupon,
              code: validCoupons[index].code
          }))

          localStorage.setItem('coupons', JSON.stringify(state.coupons))
          this.dispatch('Cart/addValidCoupon', validCoupons)
        } else { 
          state.coupons = []
        }
      })
  },
  EXISTING_COUPON(state, msg) {
    state.existingCouponError = msg
  },
  SET_COUPONS(state, coupons) {
    state.coupons = coupons
  },
  SET_COUPONS_ERRORS(state, errors) {
    if (errors === null) {
      state.errorsCoupons = []
      return
    }
    const errorsKeys = Object.keys(errors)
    state.errorsCoupons = errorsKeys.map(key => {
      state.coupons = state.coupons.filter(coupon => coupon.code !== key)

      return {
        [key]: errors[key]
      }
    })

    localStorage.setItem('coupons', JSON.stringify(state.coupons))
  },
  SET_GENERIC_ERRORS(state, errors) {
    if (errors === null) {
      state.genericErrors = []
      return
    }

    state.genericErrors = errors.genericErrors
    state.coupons = state.coupons.filter(coupon => coupon.code !== errors.coupon.toUpperCase())
    localStorage.setItem('coupons', JSON.stringify(state.coupons))
  }
}

const getters = {
  getRestaurantInfo(state) {
    return state.restaurant
  },
  getDeliveryAddress() {
    return state.deliveryAddress
  },
  getOrderInfo() {
    return state.orderInfo
  },
  getIfAdult() {
    return state.isAdult
  },
  getIfCouponsValidate() {
    return state.validCoupons.length > 0
  },
  getProductIndexPosition: (state) => (index) => {
    if (typeof index === 'number') {
      return state.orderInfo.find((order, idx) => idx == index)
    }
  },
  cartItemCount(state) {
    let count = 0
    state.orderInfo.forEach(product => {
      if (product.isProductAvailable === true && product.hasUnavailableModifiers.length === 0 && product.hasUnavailableProducts.length === 0) {
        count += product.quantity
      }
    })
    return count
  },

  cartTotalPrice(state) {
    let subTotal = 0
    let total = 0
    
    state.orderInfo.forEach(product => {
      if (product.isProductAvailable && product.hasUnavailableModifiers.length == 0) {
        subTotal += product.price * product.quantity
        product.modifiers.forEach(modifier => {
          subTotal += modifier.price * product.quantity
        })
      }
    })

    total = subTotal + state.config.serviceCost
    if(state.config.hasDeliveryCost) {
      total += state.config.deliveryCost
    }

    if (state.validCoupons.length) {
      state.validCoupons.forEach(coupon => {
        if (coupon.discount) {
          total -= coupon.discount
        }
      })
    }

    return { subTotal, total }
  },

  getUpsellingProducts(state) {
    return state.upsellingProducts
  },

  prepareCartInfo(state, getters) {
    let shippingMethod = state.shippingMethod
    let orderAddress = state.deliveryAddress
    let orderTime = state.orderTime
    let line = 1
    
    let cartContents = {
      cartId: state.cartId,
      orderDate: null,
      products: [],
      address: {
        latitude: orderAddress ? orderAddress.coordinates.lat : '',
        longitude: orderAddress ? orderAddress.coordinates.lng : '',
      },
      coupons: []
    }
    if (shippingMethod == 'delivery') {
      cartContents.address.street = orderAddress ? orderAddress.streetName : ''
      cartContents.address.streetNumber = orderAddress ?  orderAddress.streetNumber : ''
      cartContents.address.postalCode = orderAddress ?  orderAddress.postalCode : ''
      cartContents.address.city = orderAddress ?  orderAddress.city : ''
      //To-Do: esto hay que refactorizarlo @Guido
      cartContents.address.extraAddressInfo = `${orderAddress.details.block.length > 0 ? `Bloque: ${orderAddress.details.block}.` : ''}${orderAddress.details.floor.length > 0 ? ` Piso: ${orderAddress.details.floor}.` : ''}${orderAddress.details.door.length > 0 ? ` Puerta: ${orderAddress.details.door}.` : ''}${orderAddress.details.staircase.length > 0 ? ` Escalera: ${orderAddress.details.staircase}` : ''}`
    }

    state.orderInfo.forEach(product => {
      let modifiersToCheck = []
      let productsToCheck = []
      product.modifiers.forEach(modifier => modifiersToCheck.push(modifier.plu))
      product.hasComboProducts.forEach(comboProduct => productsToCheck.push(comboProduct.plu))
      cartContents.products.push({
        plu: product.plu,
        modifiers: modifiersToCheck,
        products: productsToCheck,
        quantity: product.quantity,
        line: line++
      })
    })

    state.coupons.forEach((coupon) => {
      cartContents.coupons.push({
        code: coupon.code,
        triggerChosenProducts: coupon.triggerChosenProducts,
        promotionChosenProducts: coupon.promotionChosenProducts
      })
    })
    orderTime !== null ? cartContents.orderDate = orderTime / 1000 : null
    return cartContents
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
