<template>
  <div class="container">
    <div class="fill">
      <span v-if="restNotFound">
        <h2>Disculpe, no disponemos del catálogo en este momento.</h2>
        <div class="link">
          <router-link :to="{ name: 'home' }">Ir a la página principal</router-link>
      </div>
      </span>
      <template v-else>
        <h2>Completa los datos para ver nuestra carta</h2>
          <OrderTime 
            :availableRestaurants="availableRestaurant" 
            :chosenLocation="0" 
            v-model:receiveOrderTime="receiveOrderTime" 
            v-model:scheduledTime="scheduledTime"/>
          <IsAdult v-model:isAdult="isAdult" />
          <span class="continue" :class="{ 'disable_item': isAdult === null }">
            <button @click="validateDataAndContinue" class="btn">Ver menú</button>
          </span>
      </template>
    </div>
  </div>
</template>

<script>
import OrderTime from '../components/StartModal/OrderTime.vue'
import IsAdult from '../components/StartModal/IsAdult.vue'
import { apiClient } from '@/services/ApiClient'
import { mapActions } from 'vuex'

export default {
  name: 'StartOrderByRestCode',
  components: {
    OrderTime,
    IsAdult
  },
  data() {
    return {
      receiveOrderTime: 'now',
      scheduledTime: null,
      isAdult: JSON.parse(localStorage.getItem('isAdult')),
      availableRestaurant: [],
      shippingMethod: 'takeaway',
      deliveryAddress: {
        city: '',
        coordinates: { 
          lat: 0, 
          lng: 0
        },
        details: { door: '', block: '', staircase: '', floor: '' },
        fullAddress: '',
        postalCode: '',
        streetName: '',
        streetNumber: '',
        visibleAddress: '',
      },
      restNotFound: false
    }
  },
  methods: {
    ...mapActions('Cart', ['saveConfig']),
    validateDataAndContinue() {
      this.saveConfig(['restaurant', this.availableRestaurant[0]])
      this.saveConfig(['orderTime', this.scheduledTime])
      this.saveConfig(['deliveryAddress', this.deliveryAddress])
      this.saveConfig(['isAdult', this.isAdult])
      this.$router.push({name: 'catalog'})
    }
  },
  mounted() {
    this.saveConfig(['shippingMethod', this.shippingMethod])
    const restaurantInfo = () => {
      return new Promise(() => {
        apiClient.post(`/restaurant/search-takeaway/${this.$route.params.code}`)
          .then(response => {
            this.availableRestaurant = [response.data]
            this.deliveryAddress.coordinates.lat = response.data.latitude
            this.deliveryAddress.coordinates.lng = response.data.longitude
            this.deliveryAddress.fullAddress = response.data.address
          })
          .catch(err => this.restNotFound = true)
      })
    }
    restaurantInfo()
  }
}
</script>

<style scoped lang="scss">

.container {
  padding: 30px;
  .continue {
    display: flex;
    justify-content: center;
    .btn {
      padding: 10px 60px;
    }
  }
  .link {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 15px 0;
		a {
			font-size: $fs-s-small;
			color: $c-black;
			margin-left: 5px;
			text-decoration: underline;
		}
	}

  @include tablet {
    .fill {
      max-width: 70%;
      margin: 0 auto;
      margin-top: 120px;
    }
    .continue {
      justify-content: flex-end;
    }
  }
}
</style>
